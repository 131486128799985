import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  Button,
  Grid,
  Input,
  Paper,
  TablePagination,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import saveAs from "file-saver";
import React, { useContext, useEffect, useState } from "react";
import { Navigate, useMatch } from "react-location";
import { LocationGenerics } from "../App";
import Subheader from "../components/Subheader";
import { AuthContext } from "../context/auth.context";
import {
  deleteBlob,
  downloadBlobFromBuffer,
  loadBlobs,
  uploadBlob,
} from "../utils/blobsUtils";
import { DNA } from "react-loader-spinner";

const columns = [
  { id: "name", label: "Name" },
  { id: "size", label: "Size" },
  {
    id: "created-at",
    label: "Created at",
  },
  {
    id: "modified-at",
    label: "Modified at",
  },
  {
    id: "download",
    label: "Download",
  },
  {
    id: "delete",
    label: "Delete",
  },
];

export function Blobs() {
  const routeMatch = useMatch<LocationGenerics>();
  const containerName = routeMatch.params.containerName;

  const authContext = useContext(AuthContext);

  const [blobs, setBlobs] = useState(routeMatch.data.blobs);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [failedUploadedFiles, setFailedUploadedFiles] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)


  useEffect(() => {
    if (blobs && Array.isArray(blobs)) setCount(blobs.length);
  }, []);

  useEffect(() => {
    async function handleUpload() {
      if (selectedFiles && selectedFiles.length > 0) {
        const blobResponse = await uploadBlob(selectedFiles, containerName);
        const failedUpload = blobResponse.filter(res => !res.isUploaded)
        if (!failedUpload.length) {
          const newBlobs = await loadBlobs(containerName) 
          setBlobs(newBlobs);
        }
        else {
          setFailedUploadedFiles(failedUpload.map(failedFile => failedFile.file.name))
        }
        setSelectedFiles([]);  // reset array
      }
    }
    handleUpload();
    setLoading(false);
  }, [loading]);

  const fileUploadHander = () => {
    setLoading(true);
  };

  

  // const downloadBlobHandler = async (blobName: string) => {
  //   try {
  //     const blobUrl: string = await downloadBlob(containerName, blobName);
  //     saveAs(blobUrl, blobName);
  //   } catch (error: any) {
  //     console.error(error.message);
  //   }
  // };

  const downloadBlobFromBufferHandler = async (blobName: string) => {
    try {
      const blob: Blob = await downloadBlobFromBuffer(containerName, blobName);
      saveAs(blob, blobName);
    } catch (error: any) {
      console.error(error.message);
    }
  };

  const deleteBlobHandler = async (blobName: string) => {
    const isDeleted: boolean = await deleteBlob(containerName, blobName);
    if (isDeleted) setBlobs(await loadBlobs(containerName));
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (Array.isArray(blobs)) {
    if (
      authContext?.auth.isAuth &&
      authContext?.auth.expires &&
      authContext?.auth.expires > Date.now()
    ) {
      return (
        <div>
          <Subheader title="List of blobs" navigation={true}></Subheader>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper className="upload">
                  <Button component="label">
                    <Input type="file" onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedFiles(Array.from(event.target.files || []))} inputProps={{multiple: true}} />
                  </Button>
                  <Button variant="contained" onClick={fileUploadHander}>
                    Upload
                  </Button>
                </Paper>
              </Grid>
              <DNA visible={loading}  
                   height="100"  
                   width="100"  
                   ariaLabel="dna-loading"  
                   wrapperStyle={{"margin":"auto"}}  
                   wrapperClass="dna-wrapper"/>
              {!loading && 
              <Grid item xs={12}>
                <Paper>
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell key={column.id}>
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {blobs
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((blob, i) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={i}
                              >
                                <TableCell>{blob.name} </TableCell>
                                <TableCell>
                                  {blob.properties.contentLength}
                                </TableCell>
                                <TableCell>
                                  {/* TODO: Check this */}
                                  {/* {blob.properties.createdOn?.toLocaleDateString()} */}
                                  {blob.properties.createdOn}
                                </TableCell>
                                <TableCell>
                                  {/* TODO: Check this */}
                                  {/* {blob.properties.lastModified?.toLocaleDateString()} */}
                                  {blob.properties.lastModified}
                                </TableCell>
                                <TableCell>
                                  <Button
                                    onClick={() =>
                                      downloadBlobFromBufferHandler(blob.name)
                                    }
                                  >
                                    <DownloadIcon />
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <Button
                                    onClick={() => deleteBlobHandler(blob.name)}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </Grid>}
            </Grid>
          </Box>
        </div>
      );
    } else {
      localStorage.removeItem("accessToken");
      sessionStorage.clear();
      return <Navigate to="/" />;
    }
  } else {
    return (
      <>
        <Subheader title="List of blobs" navigation={true}></Subheader>
        <Box sx={{ flexGrow: 1 }}>
          <h3>{blobs?.error} - Please contact the administrator</h3>
          <div>
            <p>{failedUploadedFiles}</p>
          </div>
        </Box>
      </>
    );
  }
}
